import React from 'react'

import styles from './LoadingBubbles.module.css'

const LoadingBubbles = () => {
  return (
    <div className={styles.loadingContainer} data-testid="loadingBubbles">
      <div className={styles.ball1} />
      <div className={styles.ball2} />
      <div className={styles.ball3} />
      <div className={styles.ball4} />
    </div>
  )
}

export default LoadingBubbles
