import Logo from 'assets/logo.svg'
import classnames from 'classnames'
import SpaLink from 'components/SpaLink/SpaLink'
import { NavigationLabel } from 'frontend/enums'
import { SEARCH_TERM_KEY } from 'frontend/lib/constants'
import { CategoryItem } from 'frontend/types'
import useStorage from 'frontend/utils/hooks/useStorage'
import React, { useState } from 'react'
import CategoriesMenu from './components/CategoriesMenu/CategoriesMenu'
import NavDropdown from './components/NavDropdown/NavDropdown'
import {
  LAST_ELEMENT_IN_PRIMARY_NAVIGATION,
  QUICK_SEARCH_INPUT_ID,
} from './components/QuickSearchConstants'
import QuickSearchInput from './components/QuickSearchInput/QuickSearchInput'
import QuickSearchResultsContainer from './components/QuickSearchResults/QuickSearchResultsContainer'
import styles from './PrimaryNavigation.module.css'
import { primaryNavigationClick } from 'frontend/utils/analytics/dataLayerDispatchers'
import { useRouter } from 'next/router'

type PrimaryNavigationProps = {
  categoryMenuItems: CategoryItem[]
}

const PrimaryNavigation = ({ categoryMenuItems }: PrimaryNavigationProps) => {
  const { storageValue: searchTerm, setStorageValue: setSearchTerm } =
    useStorage(SEARCH_TERM_KEY, true)

  const [categoriesMenuOpen, setCategoriesMenuOpen] = useState(false)
  const [isSearchFieldFocused, setIsSearchFieldFocused] = useState(false)
  const [hasSearchBeenTouched, setHasSearchBeenTouched] = useState(false)
  const [searchFormSubmitted, setSearchFormSubmitted] = useState(false)

  const router = useRouter()

  const handleDropdown = () => {
    if (!categoriesMenuOpen) {
      setCategoriesMenuOpen(true)
      primaryNavigationClick('Kategorier')
    } else {
      setCategoriesMenuOpen(false)
    }
  }

  const handleSearchBlur = () => {
    setIsSearchFieldFocused(false)
  }

  const handleSearchFocus = () => {
    setHasSearchBeenTouched(true)
    setIsSearchFieldFocused(true)
    setCategoriesMenuOpen(false)
  }

  const handleResetSearchInput = () => {
    setSearchTerm('')
    document.getElementById(QUICK_SEARCH_INPUT_ID)?.focus()
  }

  const currentFullPath = router.asPath

  return (
    <nav aria-label="Main Navigation" className={styles.navigation}>
      <ul className={classnames('menu', styles.menuWrapper)}>
        <li className={styles.logoItem}>
          <SpaLink
            href="/"
            className={styles.logoLink}
            data-trk-primary-navigation="Logo"
            aria-label="UR Play logotyp, gå till startsidan"
            onClick={() => primaryNavigationClick('Start')}
          >
            <Logo className={styles.logo} focusable="false" />
          </SpaLink>
        </li>
        <li data-testid="category-nav" className={styles.navItem}>
          <NavDropdown
            currentFullPath={currentFullPath}
            handleDropdown={handleDropdown}
            isOpen={categoriesMenuOpen}
            label={NavigationLabel.CATEGORIES}
          >
            <CategoriesMenu
              onClick={() => setCategoriesMenuOpen(false)}
              categoryMenuItems={categoryMenuItems}
              isOpen={categoriesMenuOpen}
            />
          </NavDropdown>
        </li>
        <li data-testid="education-nav" className={styles.navItem}>
          <SpaLink
            href="/utbildning"
            className={styles.headerNavigation}
            data-trk-primary-navigation="Lärare"
            onClick={() => primaryNavigationClick('Lärare')}
          >
            Lärare
          </SpaLink>
        </li>
        <li data-testid="children-nav" className={styles.navItem}>
          <SpaLink
            href="/barn"
            className={styles.headerNavigation}
            data-trk-primary-navigation="Barn"
            onClick={() => primaryNavigationClick('Barn')}
          >
            Barn
          </SpaLink>
        </li>
        <li className={classnames(styles.navItem, styles.myPageNavItem)}>
          <SpaLink
            href="/min-sida"
            id={LAST_ELEMENT_IN_PRIMARY_NAVIGATION}
            className={styles.headerNavigation}
            data-trk-my-page="navigationslänk"
            onClick={() => primaryNavigationClick('Min sida')}
          >
            Min sida
          </SpaLink>
        </li>
      </ul>
      <div className={classnames(styles.navItem, styles.searchItem)}>
        <QuickSearchInput
          handleSearchBlur={handleSearchBlur}
          handleSearchFocus={handleSearchFocus}
          handleSearchInput={event => setSearchTerm(event.target.value)}
          searchTerm={searchTerm || ''}
          resetSearchInput={handleResetSearchInput}
          handleSubmit={() => setSearchFormSubmitted(true)}
          disableSearch={searchFormSubmitted}
        />
      </div>

      <QuickSearchResultsContainer
        handleSearchEscapePressed={() =>
          document.getElementById(QUICK_SEARCH_INPUT_ID)?.blur()
        }
        searchTerm={searchTerm || ''}
        isSearchFieldFocused={isSearchFieldFocused}
        hasSearchBeenTouched={hasSearchBeenTouched}
        disableSearch={searchFormSubmitted}
        setDisableSearch={() => setSearchFormSubmitted(true)}
      />
    </nav>
  )
}

export default PrimaryNavigation
