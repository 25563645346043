import { MAIN_CONTENT_ID } from 'components/Layout/Layout'
import { CategoryItem } from 'frontend/types'
import React from 'react'
import PrimaryNavigation from './PrimaryNavigation/PrimaryNavigation'
import styles from './SiteHeader.module.css'

type Props = {
  categoryMenuItems: CategoryItem[]
}

export const RESET_FOCUS_ID = 'resetFocus'

const SiteHeader = ({ categoryMenuItems }: Props) => {
  return (
    <div className={styles.siteHeader} data-testid="site-header">
      <div className={styles.wrapper}>
        <button
          id={RESET_FOCUS_ID}
          tabIndex={-1}
          aria-hidden={true}
          className={styles.resetFocusButton}
        />
        <a className={styles.accessibilityLinks} href={`#${MAIN_CONTENT_ID}`}>
          Till huvudinnehåll
        </a>

        <PrimaryNavigation categoryMenuItems={categoryMenuItems} />
      </div>
    </div>
  )
}

export default SiteHeader
