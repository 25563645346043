import React, { useRef } from 'react'
import classnames from 'classnames'
import { NavigationLabel, Direction } from 'frontend/enums'
import Caret from 'components/Caret/Caret'
import styles from './NavDropdown.module.css'

type NavDropdownProps = {
  children: React.ReactNode
  currentFullPath: string
  handleDropdown: () => void
  isOpen: boolean
  label: NavigationLabel
}

const NavDropdown = ({
  children,
  handleDropdown,
  isOpen,
  label,
}: NavDropdownProps) => {
  const menuWrapper = useRef<HTMLDivElement>(null)

  const direction = isOpen ? Direction.Up : Direction.Down

  const menuState = isOpen ? 'open' : 'closed'
  const dataTrkMenuState = {
    [`data-trk-primary-navigation-${label.toLowerCase()}`]: menuState,
  }

  return (
    <>
      <button
        aria-expanded={isOpen}
        {...dataTrkMenuState}
        className={classnames(styles.navButton, {
          [`${styles.active}`]: isOpen,
        })}
        data-trk-primary-navigation={label}
        onClick={() => handleDropdown()}
        type="button"
      >
        {label}
        <Caret direction={direction} styling={styles.caret} />
      </button>
      <div
        ref={menuWrapper}
        className={classnames(styles.navMenu, {
          [`${styles.expandedNavMenu}`]: isOpen,
        })}
      >
        {children}
      </div>
    </>
  )
}

export default NavDropdown
