import { EmptyObject } from 'backend/services/v1/urasService.types'
import {
  BlockTracking,
  PreviousSearchTerms,
  Product,
  QuickSearchCategory,
  QuickSearchProgram,
  QuickSearchSeries,
  UrasProductMetadata,
} from 'frontend/types'

interface DataLayerPushProps {
  event: string
  [key: string]: unknown
}

const dataLayerPush = (data: DataLayerPushProps) => {
  window.dataLayer?.push(data)
}

// quick-search events
export const internalSearchLeave = (searchQuery: string) =>
  dataLayerPush({ event: 'internal_search_leave', searchQuery })

export const internalSearchSubmitClick = (searchQuery: string) => {
  dataLayerPush({ event: 'internal_search_submit_click', searchQuery })
}

export const internalSearchSubmitEnter = (searchQuery: string) => {
  dataLayerPush({ event: 'internal_search_submit_enter', searchQuery })
}

const PREVIOUS_AUTOSUGGEST_CLICK_EVENT =
  'Previous Internal Search Autosuggest Click'
const AUTOSUGGEST_CLICK_EVENT = 'Internal Search Autosuggest Click'

export const quickSearchClickOnPreviousSearchTerm = (
  record: PreviousSearchTerms,
) => {
  dataLayerPush({
    event: PREVIOUS_AUTOSUGGEST_CLICK_EVENT,
    searchTerm: record.searchTerm,
    clickedItemType: 'previous',
    clickedItemName: record.searchTerm,
  })
}

export const quickSearchClickOnAutoSuggestProduct = (
  query: string,
  record: QuickSearchProgram | QuickSearchSeries,
  isPreviousSearchedProduct: boolean,
) => {
  const trackEvent = {
    event: isPreviousSearchedProduct
      ? PREVIOUS_AUTOSUGGEST_CLICK_EVENT
      : AUTOSUGGEST_CLICK_EVENT,
    searchTerm: query,
    clickedItemType: record.productType,
    clickedItemName: record.title,
  }
  dataLayerPush(trackEvent)
}

export const quickSearchClickOnAutoSuggestCategory = (
  query: string,
  categoryItem: QuickSearchCategory,
) => {
  dataLayerPush({
    event: 'Internal Search Autosuggest Click',
    searchTerm: query,
    clickedItemType: 'category',
    clickedItemName: categoryItem.name,
  })
}

export const internalSearchNoResults = (searchQuery: string) =>
  dataLayerPush({
    event: 'internal_search_no_results',
    searchQuery,
  })

// filter events
export const filterPedagogicalMaterialClick = (onOff: string) =>
  dataLayerPush({
    event: 'filter_pedagogical_material_click',
    filter_pedagogical_material: onOff,
  })

export const filterAudioDescriptionClick = (onOff: string) =>
  dataLayerPush({
    event: 'filter_audio_description_click',
    filter_audio_description: onOff,
  })

export const filterSignInterpretationClick = (onOff: string) =>
  dataLayerPush({
    event: 'filter_sign_interpretation_click',
    filter_sign_interpretation: onOff,
  })

export const filterTypicalAgeRangeClick = (ageRange: string) =>
  dataLayerPush({
    event: 'filter_typical_age_range_click',
    filter_typical_age_range: ageRange,
  })

export const filterTeacherResourceClick = (teacherResource: string) =>
  dataLayerPush({
    event: 'filter_teacher_resource_click',
    filter_teacher_resource: teacherResource,
  })

export const filterInterpretationsClick = (interpretation: string) =>
  dataLayerPush({
    event: 'filter_interpretations_click',
    filter_interpretation: interpretation,
  })

export const filterLanguageClick = (language: string) =>
  dataLayerPush({
    event: 'filter_language_click',
    filter_language: language,
  })

export const filterSchoolSubjectClick = (subject: string) =>
  dataLayerPush({
    event: 'filter_school_subject_click',
    filter_school_subject: subject,
  })

export const filterMediaFormatClick = (mediaType: string) =>
  dataLayerPush({
    event: 'filter_media_format_click',
    filter_media_format: mediaType,
  })

export const filterSortClick = (sort: string) =>
  dataLayerPush({
    event: 'filter_sort_click',
    filter_sort: sort,
  })

// primary navigation click
export const primaryNavigationClick = (name: string) => {
  dataLayerPush({
    event: 'Primary Navigation Click',
    item_name: name,
  })
}

export const blockItemClick = ({
  blockTracking,
  itemIndex,
  product,
  item,
}: {
  blockTracking: BlockTracking
  itemIndex: number
  product?: Product
  item?: { title: string; link: string }
}) => {
  if (product) {
    dataLayerPush({
      event: 'Block Item Click',
      block_type: blockTracking.blockType,
      block_title: blockTracking.blockTitle,
      block_index: blockTracking.blockIndex,
      item_id: 'id' in product && product.id,
      item_link: product.link,
      item_title: product.title,
      item_index: itemIndex,
      ...('productType' in product && { product_type: product.productType }),
    })
  } else {
    dataLayerPush({
      event: 'Block Item Click',
      block_type: blockTracking.blockType,
      block_title: blockTracking.blockTitle,
      block_index: blockTracking.blockIndex,
      item_link: item?.link || '',
      item_title: item?.title || '',
      item_index: itemIndex,
    })
  }
}

// quiz
export const startQuizClick = (metadata: UrasProductMetadata | EmptyObject) => {
  if (!('id' in metadata)) {
    return
  }
  dataLayerPush({
    event: 'pedagogical_material_quiz',
    step: 'start',
    program_id: metadata?.id,
    program_name: metadata.title,
    series_name: metadata.mainTitle,
    series_id: metadata.seriesId,
  })
}

export const endQuizClick = (metadata: UrasProductMetadata | EmptyObject) => {
  if (!('id' in metadata)) {
    return
  }
  dataLayerPush({
    event: 'pedagogical_material_quiz',
    step: 'end',
    program_id: metadata?.id,
    program_name: metadata.title,
    series_name: metadata.mainTitle,
    series_id: metadata.seriesId,
  })
}

// pedagogical support
export const pedagogicalSupportNavigationClick = (pageSection: string) => {
  dataLayerPush({
    event: 'Navigation Click',
    type: 'Pedagogical Material Sidebar',
    pageSection,
  })
}

// educational menu
export const schoolMenuClick = (itemName: string) => {
  dataLayerPush({
    event: 'Filter Typical Age Range Click',
    item_name: itemName, //texten i filtervalet
    menu_type: 'undermeny',
  })
}

// new category tags menu
export const categoryTagsMenuClick = (category: string, itemName: string) => {
  dataLayerPush({
    event: 'Filter Category Tags Click',
    category,
    item_name: itemName,
    menu_type: 'undermeny',
  })
}

export const searchTagClick = (searchTagLabel: string) => {
  dataLayerPush({
    event: 'Remove filter search tag',
    search_tag: searchTagLabel,
  })
}
