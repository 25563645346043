import React from 'react'
import styles from './LineLoader.module.css'

interface Props {
  showLoadingAnimation: boolean
}

const LineLoader = ({ showLoadingAnimation }: Props) => {
  return (
    <div className={styles.lineLoaderContainer}>
      {showLoadingAnimation && (
        <div className={styles.lineLoader}>
          <div className={styles.lineLoaderElement} />
        </div>
      )}
    </div>
  )
}

export default LineLoader
