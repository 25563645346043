import classnames from 'classnames'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  internalSearchSubmitClick,
  internalSearchSubmitEnter,
} from 'frontend/utils/analytics/dataLayerDispatchers'

import usePreviousSearches from 'frontend/utils/hooks/usePreviousSearches'
import {
  QUICK_SEARCH_FORM_ID,
  QUICK_SEARCH_INPUT_ID,
  QUICK_SEARCH_QUERY_LIMIT,
} from '../QuickSearchConstants'
import styles from './QuickSearchInput.module.css'

type QuickSearchInputProps = {
  handleSearchBlur: () => void
  handleSearchFocus: () => void
  handleSearchInput: (event: ChangeEvent<HTMLInputElement>) => void
  handleSubmit: () => void
  searchTerm: string
  resetSearchInput: () => void
  disableSearch: boolean
}

const QuickSearchInput = ({
  handleSearchBlur,
  handleSearchFocus,
  handleSearchInput,
  handleSubmit,
  searchTerm,
  resetSearchInput,
  disableSearch,
}: QuickSearchInputProps) => {
  const inputRef = React.createRef<HTMLInputElement>()

  const { addPreviousSearch } = usePreviousSearches()

  const onSubmit = () => {
    const query = inputRef.current?.value || ''

    if (!disableSearch) {
      if (document.activeElement?.id === QUICK_SEARCH_INPUT_ID) {
        internalSearchSubmitEnter(query)
      } else {
        internalSearchSubmitClick(query)
      }

      if (query) {
        addPreviousSearch({
          searchTerm: query,
          params: { query },
        })
      }

      handleSubmit()
    }
  }

  const [hasLoaded, setHasLoaded] = useState(false)
  // Fixes issue with event listeners not loading fast enough on slow connections
  // Makes input field non-interactive until all JS has loaded
  useEffect(() => {
    setHasLoaded(true)
  }, [])

  return (
    <form
      id={QUICK_SEARCH_FORM_ID}
      action="/sok"
      className={styles.searchForm}
      onSubmit={onSubmit}
    >
      <input
        aria-label="Sök på UR Play. Sökförslag kommer att visas under sökfältet."
        autoComplete="off"
        className={styles.searchInput}
        data-testid="search-input"
        data-trk-search-query-input=""
        data-trk-internal-search-element="Sökfält"
        id={QUICK_SEARCH_INPUT_ID}
        name="query"
        onBlur={handleSearchBlur}
        onFocus={handleSearchFocus}
        onChange={handleSearchInput}
        value={searchTerm}
        placeholder="Program, serier, poddar"
        ref={inputRef}
        type="text"
        disabled={!hasLoaded}
        maxLength={QUICK_SEARCH_QUERY_LIMIT}
      />
      <input
        className={classnames(styles.searchInputReset, {
          [`${styles.searchInputResetSearching}`]: searchTerm.length > 0,
        })}
        data-trk-internal-search-element="Rensa"
        onClick={resetSearchInput}
        type="reset"
        value="Rensa"
      />
      <input
        className={styles.searchInputSubmit}
        data-trk-internal-search-element="Sök"
        type="submit"
        value="Sök"
        aria-label="Sök"
      />
    </form>
  )
}

export default QuickSearchInput
