import { QuickSearchCategory } from 'frontend/types'
import HighlightText from './HighlightText'
import styles from '../QuickSearchResults.module.css'
import { quickSearchClickOnAutoSuggestCategory } from 'frontend/utils/analytics/dataLayerDispatchers'
import NextImage from 'components/NextImage/NextImage'

interface Props {
  categories: QuickSearchCategory[]
  query: string
}

const CategoriesResults = ({ categories, query }: Props) => {
  return (
    <>
      {categories.map(categoryItem => (
        <li key={categoryItem.name} data-trk-internal-search-row="Categories">
          <a
            href={categoryItem.link}
            className={styles.suggestion}
            onClick={() => {
              quickSearchClickOnAutoSuggestCategory(query, categoryItem)
            }}
          >
            <figure className={styles.productImageFigure}>
              <NextImage
                src={categoryItem.iconUrl}
                fallbackSrc="/images/categories/fallback.jpg"
                alt={categoryItem.name}
                width={45}
                height={45}
                className={styles.categoryImage}
              />
            </figure>
            <div className={styles.metadataWrap}>
              <span className={styles.title}>
                <HighlightText
                  text={categoryItem.name || ''}
                  highlight={query}
                />
              </span>
              <div className={styles.metadataText}>Kategori</div>
            </div>
          </a>
        </li>
      ))}
    </>
  )
}

export default CategoriesResults
