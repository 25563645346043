import React from 'react'
import styles from '../QuickSearchResults.module.css'
import classNames from 'classnames'

type HighlightTextProps = {
  text: string
  highlight: string
}

export const escapeRegExp = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const HighlightText = ({ text, highlight }: HighlightTextProps) => {
  if (!text) return null
  if (!highlight) return <span>{text}</span>

  const queryParts = escapeRegExp(highlight.toLowerCase()).split(' ')
  const parts = text.split(new RegExp(`(${queryParts.join('|')})`, 'gi'))

  return (
    <span>
      {parts.map((part, i) => (
        <span
          className={classNames({
            [`${styles.highlightedText}`]: queryParts.includes(
              part.toLowerCase(),
            ),
          })}
          key={`${part}-${i}`}
        >
          {part}
        </span>
      ))}
    </span>
  )
}

export default HighlightText
