import React from 'react'
import ExternalLink from 'icons/external-link.svg'
import Logo from 'icons/logo-white.svg'
import styles from './BrandSection.module.css'

const BrandSection = () => {
  return (
    <div className={styles.brandWrapper}>
      <div className={styles.logo}>
        <Logo aria-hidden focusable="false" />
      </div>
      <div className={styles.links}>
        <p className={styles.paragraph}>
          <a
            href="https://www.ur.se/integritetspolicy"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Integritetspolicy
            <ExternalLink className={styles.externalIcon} focusable="false" />
          </a>
        </p>
        <p className={styles.paragraph}>
          <a
            href="https://www.ur.se/kontakt"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Kontakt
            <ExternalLink
              aria-hidden
              className={styles.externalIcon}
              focusable="false"
            />
          </a>
        </p>
        <p className={styles.paragraph}>
          Ansvarig utgivare: Margaretha Eriksson
        </p>
        <p className={styles.paragraph}>Sveriges Utbildningsradio AB</p>
      </div>
    </div>
  )
}

export default BrandSection
