import classnames from 'classnames'
import { useEffect, useState } from 'react'
import styles from './EnvironmentLabel.module.css'

const EnvironmentLabel = () => {
  const [isStage, setIsStage] = useState(false)
  const [isLocal, setIsLocal] = useState(false)
  const [prNumber, setPrNumber] = useState('')

  useEffect(() => {
    if (window.location.host.includes('stage')) {
      setIsStage(true)
      if (window.location.host.includes('pr')) {
        setPrNumber(window.location.host.split('-')[1])
      }
    } else if (window.location.host.includes('local')) {
      setIsLocal(true)
    }
  }, [])

  if (!isStage && !isLocal) return null

  return (
    <div data-testid="environmentLabel" className={styles.stageFlag}>
      {prNumber ? (
        <a
          className={styles.stageText}
          href={`https://github.com/sveriges-utbildningsradio/urplay-web/pull/${prNumber}`}
        >
          PR:{prNumber}
        </a>
      ) : (
        <p
          className={classnames(styles.stageText, {
            [`${styles.localColor}`]: isLocal,
          })}
        >
          {isStage ? 'Stage' : 'Local'}
        </p>
      )}
    </div>
  )
}

export default EnvironmentLabel
