import React from 'react'
import classnames from 'classnames'
import ExternalLink from 'assets/icons/external-link.svg'
import AudioDescribedIcon from 'assets/icons/audio-described.svg'
import SignLanguageInterpretedIcon from 'assets/icons/sign-language.svg'
import RadioIcon from 'assets/icons/pod.svg'
import styles from './Badges.module.css'

type BadgesProps = {
  isAudioDescribed?: boolean
  isExternalLinkProduct?: boolean
  isRadioProduct?: boolean
  isSignLanguageInterpreted?: boolean
  isInFMGGrid?: boolean
  isQuickSearchBadge?: boolean
}

const Badges = ({
  isRadioProduct,
  isExternalLinkProduct,
  isAudioDescribed,
  isSignLanguageInterpreted,
  isInFMGGrid,
  isQuickSearchBadge,
}: BadgesProps) => {
  const handleIcons = () => {
    if (isRadioProduct) {
      return (
        <RadioIcon
          data-testid="badge-radio"
          focusable="false"
          aria-label="Podd att lyssna på"
        />
      )
    }
    if (isExternalLinkProduct) {
      return (
        <ExternalLink data-testid="badge-external-link" focusable="false" />
      )
    }
    if (isAudioDescribed) {
      return (
        <AudioDescribedIcon
          data-testid="badge-audio-described"
          focusable="false"
          aria-label="Syntolkat"
        />
      )
    }
    if (isSignLanguageInterpreted) {
      return (
        <SignLanguageInterpretedIcon
          data-testid="badge-sign-language"
          focusable="false"
          aria-label="Teckenspråkstolkat"
        />
      )
    }

    return null
  }

  const shouldShowIcon =
    isExternalLinkProduct ||
    isRadioProduct ||
    isAudioDescribed ||
    isSignLanguageInterpreted

  if (!shouldShowIcon) {
    return null
  }

  return (
    <span
      data-exp="card-badge"
      className={classnames(styles.badge, {
        [`${styles.radio}`]: isRadioProduct,
        [`${styles.fmgIcon}`]: isInFMGGrid,
        [`${styles.quickSearchIcon}`]: isQuickSearchBadge,
      })}
    >
      {shouldShowIcon && handleIcons()}
    </span>
  )
}

export default Badges
