import classnames from 'classnames'
import NextImage from 'components/NextImage/NextImage'
import SpaLink from 'components/SpaLink/SpaLink'
import { Categories } from 'frontend/enums'
import { CategoryItem } from 'frontend/types'
import React from 'react'
import styles from './CategoriesMenu.module.css'

type CategoriesMenuProps = {
  isOpen: boolean
  categoryMenuItems: CategoryItem[]
  onClick: () => void
}

const ListItem = ({
  category,
  children,
  href = `/bladdra/${category.slug}`,
  onClick,
}: {
  category: CategoryItem
  href?: string
  children: React.ReactNode
  onClick: () => void
}) => {
  return (
    <li className={styles.categoryItem}>
      <NextImage
        className={styles.categoryImage}
        src={category.imageUrl}
        alt={''}
        width={640}
        height={360}
        fallbackSrc="/images/categories/fallback.jpg"
      />
      <SpaLink
        href={href}
        onClick={onClick}
        className={styles.categoryLink}
        data-trk-categories-menu={category.name}
      >
        <div className={styles.categoryName}>{children}</div>
      </SpaLink>
    </li>
  )
}

const CategoriesMenu = ({
  isOpen,
  categoryMenuItems,
  onClick,
}: CategoriesMenuProps) => (
  <div
    className={classnames(styles.categoriesMenu, {
      [`${styles.open}`]: isOpen,
    })}
    data-testid="categories-menu"
  >
    <div className={styles.innerWrapper}>
      <ul className={styles.listWrapper}>
        {categoryMenuItems
          .filter(({ slug }) => slug !== Categories.CHILDREN)
          .map(category => {
            return (
              <ListItem
                category={category}
                key={category.slug}
                onClick={onClick}
              >
                {category.name}
              </ListItem>
            )
          })}
        <ListItem
          onClick={onClick}
          category={
            {
              slug: 'alla-program',
              name: 'Alla Program',
              imageUrl: '/images/categoriesBlock/alla-program.jpg',
            } as CategoryItem
          }
        >
          Program A till Ö
        </ListItem>
      </ul>
    </div>
  </div>
)

export default CategoriesMenu
