import classnames from 'classnames'
import { MAIN_CONTENT_ID } from 'components/Layout/Layout'
import debounce from 'debounce-promise'
import { KeyCodes } from 'frontend/enums'
import { getSuggestions } from 'frontend/lib/Api'
import {
  QuickSearchCategory,
  QuickSearchProgram,
  QuickSearchResponse,
  QuickSearchSeries,
} from 'frontend/types'
import { internalSearchLeave } from 'frontend/utils/analytics/dataLayerDispatchers'
import { errorIsEqual, logError } from 'frontend/utils/helpers'
import useKeys from 'frontend/utils/hooks/useKeys'
import React, { useCallback, useEffect, useState } from 'react'
import {
  LAST_ELEMENT_IN_PRIMARY_NAVIGATION,
  QUICK_SEARCH_INPUT_ID,
} from '../QuickSearchConstants'
import QuickSearchResults from './QuickSearchResults'
import styles from './QuickSearchResultsContainer.module.css'

const DEBOUNCE_TIMEOUT = 500
const MAX_RETRIES = 3
const SEARCH_DISABLED_ERROR = new Error('Search already submitted')

interface Props {
  searchTerm: string
  hasSearchBeenTouched: boolean
  isSearchFieldFocused: boolean
  disableSearch: boolean
  handleSearchEscapePressed: () => void
  setDisableSearch: () => void
}

const isVisible = (el: HTMLElement) => el.offsetParent !== null

const getFirstFocusableElementInMain = () => {
  const main = document.getElementById(MAIN_CONTENT_ID)
  const focusableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'

  const firstVisibleItem = Array.from(
    main?.querySelectorAll(focusableElements) || [],
  ).find((el: Element) => isVisible(el as HTMLElement))

  return firstVisibleItem as HTMLElement
}

const forceFocusOnQuickSearchInput = () => {
  document.getElementById(QUICK_SEARCH_INPUT_ID)?.focus()
}

const QuickSearchResultsContainer = ({
  searchTerm,
  hasSearchBeenTouched,
  isSearchFieldFocused,
  disableSearch,
  handleSearchEscapePressed,
  setDisableSearch,
}: Props) => {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState<
    (QuickSearchSeries | QuickSearchProgram)[]
  >([])
  const [categories, setCategories] = useState<QuickSearchCategory[]>([])

  useEffect(() => {
    if (isSearchFieldFocused) {
      setIsOpen(true)
    }
  }, [isSearchFieldFocused])

  const handleClickOutside = useCallback(() => {
    if (isOpen && !disableSearch) {
      internalSearchLeave(query)
    }

    setIsOpen(false)
  }, [isOpen, disableSearch])

  const onEscapePress = () => {
    handleClickOutside()
    handleSearchEscapePressed()
  }

  useKeys(isOpen, {
    [KeyCodes.ESCAPE_KEY]: onEscapePress,
  })

  useEffect(() => {
    const closeBackDrop = () => setIsOpen(false)
    document
      .getElementById(LAST_ELEMENT_IN_PRIMARY_NAVIGATION)
      ?.addEventListener('focus', closeBackDrop)
    return () => {
      document
        .getElementById(LAST_ELEMENT_IN_PRIMARY_NAVIGATION)
        ?.removeEventListener('focus', closeBackDrop)
    }
  }, [])

  const searchAPI = useCallback(
    async (search: string): Promise<QuickSearchResponse> => {
      if (disableSearch) {
        return Promise.reject(SEARCH_DISABLED_ERROR)
      }

      return getSuggestions(search)
    },
    [disableSearch],
  )

  const handleSearch = useCallback(
    debounce<
      (query: string, retries?: number, previousError?: Error) => Promise<void>
    >(
      async (
        searchQuery: string,
        retries: number = MAX_RETRIES,
        previousError: Error | null = null,
      ) => {
        try {
          const data = await searchAPI(searchQuery)
          setIsLoading(false)
          setHasError(false)
          setProducts(data.results)
          setCategories(data.categoryResults)
        } catch (error) {
          if (!errorIsEqual(error as Error, SEARCH_DISABLED_ERROR)) {
            if (!errorIsEqual(error as Error, previousError as Error)) {
              logError(error as Error)
            }
            if (retries > 0) {
              handleSearch(searchQuery, retries - 1, error as Error)
            } else {
              setHasError(true)
              setIsLoading(false)
              setProducts([])

              setCategories([])
            }
          }
        }
      },
      DEBOUNCE_TIMEOUT,
    ),
    [],
  )

  useEffect(() => {
    if (!disableSearch) {
      if (!searchTerm.length) {
        setQuery(searchTerm)
      }
      if (searchTerm.length && hasSearchBeenTouched) {
        setIsOpen(true)
        setIsLoading(true)
        setHasError(false)
        setQuery(searchTerm)
        handleSearch(searchTerm)
      }
    }
  }, [searchTerm, hasSearchBeenTouched, disableSearch])

  const forceFocusOnFirstInMain = () => {
    setIsOpen(false)
    getFirstFocusableElementInMain().focus()
  }

  return (
    <>
      <QuickSearchResults
        isOpen={isOpen}
        isLoading={isLoading}
        hasError={hasError}
        products={products}
        query={query}
        categories={categories}
        setDisableSearch={setDisableSearch}
      />
      <div
        data-testid="backdrop"
        aria-hidden
        className={classnames(styles.backdrop, {
          [`${styles.noBackdrop}`]: !isOpen,
        })}
        onClick={handleClickOutside}
      />
      <input
        aria-hidden
        data-skipa11y
        className={styles.focusGuard}
        type="text"
        onFocus={forceFocusOnFirstInMain}
      />
      <input
        aria-hidden
        data-skipa11y
        className={styles.focusGuard}
        type="text"
        onFocus={forceFocusOnQuickSearchInput}
      />
    </>
  )
}

export default QuickSearchResultsContainer
