import React from 'react'
import Tv from 'icons/tv.svg'
import Tablet from 'icons/tablet.svg'
import Computer from 'icons/computer.svg'
import ExternalLink from 'icons/external-link.svg'
import styles from './MediaList.module.css'

const MediaList = () => {
  return (
    <ul>
      <li className={styles.mediaListItem}>
        <Tablet aria-hidden className={styles.mediaIcon} focusable="false" />
        <a
          href="https://www.ur.se/vart-utbud/ur-play"
          target="_blank"
          rel="noopener"
          className={styles.link}
        >
          Mobil och surfplatta
          <ExternalLink className={styles.externalIcon} focusable="false" />
        </a>
      </li>
      <li className={styles.mediaListItem}>
        <Computer aria-hidden className={styles.mediaIcon} focusable="false" />
        <a
          href="https://www.ur.se/vart-utbud/ur-play"
          target="_blank"
          rel="noopener"
          className={styles.link}
        >
          Dator
          <ExternalLink
            aria-hidden
            className={styles.externalIcon}
            focusable="false"
          />
        </a>
      </li>
      <li className={styles.mediaListItem}>
        <Tv aria-hidden className={styles.mediaIcon} focusable="false" />
        <a
          href="https://www.ur.se/vart-utbud/ur-play"
          target="_blank"
          rel="noopener"
          className={styles.link}
        >
          TV
          <ExternalLink
            aria-hidden
            className={styles.externalIcon}
            focusable="false"
          />
        </a>
      </li>
    </ul>
  )
}

export default MediaList
