import { handleDuration } from 'frontend/utils/helpers'
import React from 'react'

export interface SingleCardMetadataProps {
  duration: number
  hideCardMetaData?: boolean
}

const Duration = ({ duration }: { duration: number }) => {
  const formattedDuration = handleDuration(duration)

  if (formattedDuration) {
    return <span data-exp="card-time">{`${formattedDuration}`}</span>
  }
}

export const Dot = () => <span> · </span>

const Episode = ({ episodeNumber }: { episodeNumber: number }) => (
  <span data-exp="card-episode">
    {`Avsnitt ${episodeNumber}`}
    <Dot />
  </span>
)

const SeriesTitle = ({ title }: { title: string }) => (
  <span data-exp="card-series">{`${title}`}</span>
)

export const SingleCardMetadata = ({
  duration,
  hideCardMetaData,
}: SingleCardMetadataProps) => {
  return (
    <>
      <Duration duration={duration} />
      {!hideCardMetaData && <Dot />}
    </>
  )
}

export const SeriesCardMetadata = ({
  hideCardMetaData = false,
}: {
  hideCardMetaData?: boolean
}) => (
  <span>
    {'Serie'}
    {!hideCardMetaData && <Dot />}
  </span>
)

export interface ProgramCardMetadataProps {
  duration: number
  episodeNumber: number | null
  isProgramOrSeriesPage?: boolean
  seriesTitle: string | null
  trailingDot?: boolean
}

export const ProgramCardMetadata = ({
  duration,
  episodeNumber,
  isProgramOrSeriesPage = false,
  seriesTitle,
  trailingDot,
}: ProgramCardMetadataProps) => {
  return (
    <>
      {isProgramOrSeriesPage && episodeNumber && (
        <Episode episodeNumber={episodeNumber} />
      )}

      <Duration duration={duration} />

      {!isProgramOrSeriesPage && (
        <>
          <Dot />
          <SeriesTitle title={seriesTitle || ''} />
        </>
      )}

      {trailingDot && <Dot />}
    </>
  )
}
