import React from 'react'

import BrandSection from './components/BrandSection/BrandSection'
import MediaList from './components/MediaList/MediaList'
import LinkList from './components/LinkList/LinkList'
import styles from './Footer.module.css'
import { getImageProps } from 'next/image'
import { getBackgroundImage } from 'frontend/utils/helpers'

const Footer = () => {
  const {
    props: { srcSet },
  } = getImageProps({
    alt: '',
    width: '1162',
    height: '409',
    src: '/images/pattern-raspberry-footer.svg',
  })

  const backgroundImage = getBackgroundImage(srcSet)

  return (
    <div className={styles.footerWrapper} style={{ backgroundImage }}>
      <div className={styles.borderBottom}>
        <div className={styles.topContentWrapper}>
          <div className={styles.footerContentMedia}>
            <MediaList />
          </div>
          <div className={styles.footerContentLink}>
            <LinkList />
          </div>
        </div>
      </div>
      <div className={styles.footerContentBrand}>
        <BrandSection />
      </div>
    </div>
  )
}

export default Footer
