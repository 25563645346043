import UpArrow from 'assets/icons/up-arrow-dark.svg'
import { RoutePathname } from 'frontend/enums'
import { windowIsAvailable } from 'frontend/utils/helpers'
import React, { useEffect, useRef, useState } from 'react'
import styles from './GoToTop.module.css'
import { useRouter } from 'next/router'

const GoToTop = () => {
  const SCROLL_THRESHOLD = 100

  const router = useRouter()
  const lastScrollPosition = useRef(0)
  const [showButton, setShowButton] = useState(false)

  const scrollToTop = () => {
    if (windowIsAvailable()) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    const YoffSet = (windowIsAvailable() && window.pageYOffset) || 0
    const isScrollingUp = lastScrollPosition.current > YoffSet

    if (YoffSet > SCROLL_THRESHOLD && isScrollingUp) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }

    lastScrollPosition.current = YoffSet
  }

  useEffect(() => {
    const onProductPage = router.pathname === RoutePathname.PRODUCT

    if (!onProductPage) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!showButton) return null

  return (
    <div aria-hidden="true">
      <button
        className={styles.wrapper}
        data-testid="go-to-top"
        onClick={() => scrollToTop()}
        tabIndex={-1}
        type="button"
      >
        <UpArrow />
      </button>
    </div>
  )
}

export default GoToTop
