import {
  PreviousSearchTerms,
  QuickSearchProgram,
  QuickSearchSeries,
} from 'frontend/types'
import { SEARCH_HISTORY_KEY } from 'frontend/lib/constants'
import useStorage from 'frontend/utils/hooks/useStorage'

interface PreviousSearchOutput {
  previousSearches: (
    | PreviousSearchTerms
    | QuickSearchProgram
    | QuickSearchSeries
  )[]
  addPreviousSearch: (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
  ) => void
  removePreviousSearch: (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
  ) => void
}

interface PreviousSearchesLocalStorage {
  searchTerms: PreviousSearchTerms[]
  programs: QuickSearchProgram[]
  series: QuickSearchSeries[]
}

const MAX_RECORDS_TO_SAVE = 50
const MAX_RECORDS_TO_SHOW = 3

export const usePreviousSearches = (): PreviousSearchOutput => {
  const searchHistory: {
    searchTerms: PreviousSearchTerms[]
    programs: QuickSearchProgram[]
    series: QuickSearchSeries[]
  } = {
    searchTerms: [],
    programs: [],
    series: [],
  }

  const {
    storageValue: storedPreviousSearches,
    setStorageValue: setPreviousSearches,
    removeStorageValue: clearPreviousSearches,
  } = useStorage(SEARCH_HISTORY_KEY)

  let previousSearches: PreviousSearchesLocalStorage
  let validSearchTerms: PreviousSearchTerms[]

  if (storedPreviousSearches === null) {
    previousSearches = {
      searchTerms: [],
      programs: [],
      series: [],
    }
  } else {
    try {
      previousSearches = JSON.parse(storedPreviousSearches)

      validSearchTerms = previousSearches.searchTerms.filter(
        t => t.params.query,
      )

      const hasInvalidSearchTermsInStorage =
        validSearchTerms.length !== previousSearches.searchTerms.length

      if (hasInvalidSearchTermsInStorage) {
        previousSearches.searchTerms = validSearchTerms
        setPreviousSearches(JSON.stringify(previousSearches))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Wrongly formatted search history', error)
      clearPreviousSearches()
    }

    Object.keys(searchHistory).forEach(key => {
      searchHistory[key] = previousSearches[key] || []
    })
  }

  const addRecordToPreviousSearches = (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
  ) => {
    const programRecord = 'mainTitle' in record && record
    const seriesRecord = 'label' in record && record
    const searchRecord = record as PreviousSearchTerms

    if (programRecord) {
      const savedProgramsWithoutRecord = searchHistory.programs.filter(
        program => program.id !== programRecord.id,
      )
      const programsToSave = [
        programRecord,
        ...savedProgramsWithoutRecord,
      ].slice(0, MAX_RECORDS_TO_SAVE)
      setPreviousSearches(
        JSON.stringify({
          ...searchHistory,
          programs: programsToSave,
        }),
      )
    } else if (seriesRecord) {
      const savedSeriesWithoutRecord = searchHistory.series.filter(
        serie => serie.id !== seriesRecord.id,
      )
      const seriesToSave = [seriesRecord, ...savedSeriesWithoutRecord].slice(
        0,
        MAX_RECORDS_TO_SAVE,
      )
      setPreviousSearches(
        JSON.stringify({
          ...searchHistory,
          series: seriesToSave,
        }),
      )
    } else {
      const savedSearchTermsWithoutRecord = searchHistory.searchTerms.filter(
        searchTerm => searchTerm.searchTerm !== searchRecord.searchTerm,
      )
      const searchTermsToSave = [
        searchRecord,
        ...savedSearchTermsWithoutRecord,
      ].slice(0, MAX_RECORDS_TO_SAVE)
      setPreviousSearches(
        JSON.stringify({
          ...searchHistory,
          searchTerms: searchTermsToSave,
        }),
      )
    }
  }

  const removeRecordFromPreviousSearches = (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
  ) => {
    const programRecord = 'mainTitle' in record && record
    const seriesRecord = 'label' in record && record
    const searchRecord = record as PreviousSearchTerms

    if (programRecord) {
      setPreviousSearches(
        JSON.stringify({
          ...searchHistory,
          programs: searchHistory.programs.filter(
            program => program.id !== programRecord.id,
          ),
        }),
      )
    } else if (seriesRecord) {
      setPreviousSearches(
        JSON.stringify({
          ...searchHistory,
          series: searchHistory.series.filter(
            serie => serie.id !== seriesRecord.id,
          ),
        }),
      )
    } else {
      setPreviousSearches(
        JSON.stringify({
          ...searchHistory,
          searchTerms: searchHistory.searchTerms.filter(
            searchTerm => searchTerm.searchTerm !== searchRecord.searchTerm,
          ),
        }),
      )
    }
  }

  return {
    previousSearches: [
      ...searchHistory.searchTerms.slice(0, MAX_RECORDS_TO_SHOW),
      ...searchHistory.programs.slice(0, MAX_RECORDS_TO_SHOW),
      ...searchHistory.series.slice(0, MAX_RECORDS_TO_SHOW),
    ],
    addPreviousSearch: addRecordToPreviousSearches,
    removePreviousSearch: removeRecordFromPreviousSearches,
  }
}

export default usePreviousSearches
