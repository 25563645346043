import { logError } from 'frontend/utils/helpers'
import React, { Component } from 'react'
import ErrorWindow from '../ErrorWindow/ErrorWindow'

interface ErrorComponentState {
  hasError: boolean
}

class ErrorBoundary extends Component<
  { children: React.ReactNode },
  ErrorComponentState
> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    logError(error)
  }

  render() {
    const { props, state } = this

    if (state.hasError) {
      return (
        <ErrorWindow>
          {'Vi hittar inte sidan, testa att gå vidare till '}
          <a href={'/'}>Startsidan </a>
          {'eller '}
          <a href={'/bladdra/alla-program'}>Alla program.</a>
        </ErrorWindow>
      )
    }

    return <>{props.children}</>
  }
}

export default ErrorBoundary
