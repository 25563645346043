import { useStorage } from 'frontend/utils/hooks/useStorage'
import ExternalLink from 'icons/external-link.svg'
import Lock from 'icons/lock.svg'
import UnLock from 'icons/unlock.svg'
import SpaLink from 'components/SpaLink/SpaLink'
import styles from './LinkList.module.css'
import { PARENTAL_LOCK_LOCAL_STORAGE_KEY } from 'components/ParentalLock/ParentalLock'

const ListItem = ({ children }: { children: React.ReactNode }) => {
  return <li className={styles.linkListItem}>{children}</li>
}
const LinkList = () => {
  const { storageValue } = useStorage(PARENTAL_LOCK_LOCAL_STORAGE_KEY)
  const isParentalLockActive = Boolean(storageValue)
  return (
    <>
      <ul>
        <ListItem>
          <a
            href="https://www.ur.se/om-oss/sa-arbetar-ur"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Så arbetar UR
            <ExternalLink className={styles.externalIcon} focusable="false" />
          </a>
        </ListItem>
        <ListItem>
          <SpaLink href="/barnlas" className={styles.link}>
            Barnlås
            {isParentalLockActive ? (
              <Lock
                aria-hidden
                data-testid="active-lock"
                className={styles.lockIcon}
                focusable="false"
              />
            ) : (
              <UnLock
                aria-hidden
                data-testid="inactive-lock"
                className={styles.lockIcon}
                focusable="false"
              />
            )}
          </SpaLink>
        </ListItem>
        <ListItem>
          <a
            href="https://www.ur.se/tillganglighetsredogorelse"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Tillgänglighetsredogörelse
            <ExternalLink
              aria-hidden
              className={styles.externalIcon}
              focusable="false"
            />
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://www.ur.se/nyhetsbrev"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Nyhetsbrev
            <ExternalLink
              aria-hidden
              className={styles.externalIcon}
              focusable="false"
            />
          </a>
        </ListItem>
      </ul>
      <ul>
        <ListItem>
          <a
            href="https://www.ur.se"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            UR.se
            <ExternalLink
              aria-hidden
              className={styles.externalIcon}
              focusable="false"
            />
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://www.ur.se/vart-utbud/ur-access"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Access och mediecentraler
            <ExternalLink
              aria-hidden
              className={styles.externalIcon}
              focusable="false"
            />
          </a>
        </ListItem>
      </ul>
    </>
  )
}

export default LinkList
