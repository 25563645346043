import React from 'react'
import { urlEncodeParams } from 'frontend/utils/urlHelpers'
import classnames from 'classnames'
import RemoveIcon from 'assets/icons/close-dark.svg'
import {
  PreviousSearchTerms,
  QuickSearchProgram,
  QuickSearchSeries,
} from 'frontend/types'
import capitalize from 'lodash/capitalize'
import styles from '../QuickSearchResults.module.css'
import { ProductResult } from './ProductsResults'

type PreviousSearchResultsProps = {
  previousSearches: (
    | PreviousSearchTerms
    | QuickSearchProgram
    | QuickSearchSeries
  )[]
  query: string
  onClick: (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
  ) => void
  removePreviousSearch: (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
  ) => void
}

const PreviousSearchResults = ({
  previousSearches,
  query,
  onClick,
  removePreviousSearch,
}: PreviousSearchResultsProps) => (
  <>
    {previousSearches.map(previousSearch => {
      const isSearchTerm = 'searchTerm' in previousSearch
      let rowType: string
      if (isSearchTerm) {
        rowType = 'Fritext'
      } else {
        rowType = capitalize(previousSearch.productType)
      }

      return (
        <li
          key={isSearchTerm ? previousSearch.searchTerm : previousSearch.id}
          data-trk-internal-search-row={rowType}
          className={classnames(
            styles.suggestion,
            styles.previousSearchWrapper,
          )}
        >
          {isSearchTerm ? (
            <a
              className={classnames(
                styles.suggestion,
                styles.previousSearchItem,
              )}
              href={`/sok?${urlEncodeParams({
                ...previousSearch.params,
              })}`}
              onClick={() => onClick(previousSearch)}
            >
              <span className={styles.title}>{previousSearch.searchTerm}</span>
            </a>
          ) : (
            <ProductResult
              product={previousSearch}
              query={query}
              onClick={onClick}
              previousSearch
            />
          )}

          <button
            type="button"
            className={styles.removeButton}
            onClick={() => removePreviousSearch(previousSearch)}
            aria-label={`Ta bort ${
              isSearchTerm ? previousSearch.searchTerm : previousSearch.title
            } från historiken`}
            data-trk-internal-search-element="Rensa historik"
            data-testid={`remove-previous-search-${
              isSearchTerm ? previousSearch.searchTerm : previousSearch.title
            }`}
          >
            <RemoveIcon className={styles.removeButtonIcon} />
          </button>
        </li>
      )
    })}
  </>
)

export default PreviousSearchResults
