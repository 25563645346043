// eslint-disable-next-line import/prefer-default-export
export const QUICK_SEARCH_INPUT_ID = 'react-quick-search-input'

export const QUICK_SEARCH_FORM_ID = 'react-quick-search-form'

export const LAST_ELEMENT_IN_PRIMARY_NAVIGATION =
  'last-element-in-primary-navigation'

export const QUICK_SEARCH_QUERY_LIMIT = 50

export const EDUCATIONAL_LEVEL_PAGE = 'educational-level-page'

export const TYPICAL_AGE_RANGE_PAGE = 'typical-age-ranges-levels'

export const CHILD_CATEGORY_NAME = 'Barn'

export const SIGN_LANGUAGE = 'Teckenspråk'
