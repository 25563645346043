import NoSearchResultsRobot from 'assets/robots/no_search_results_robot.svg'
import NoSearchResultsRobotWhite from 'assets/robots/no_search_results_robot_white.svg'
import ServerErrorRobot from 'assets/robots/robot-server-error.svg'
import classnames from 'classnames'

import styles from './NoSearchResults.module.css'

interface NoSearchResultsProps {
  darkTheme?: boolean
  hasError?: boolean
}

const NoSearchResults = ({
  darkTheme = false,
  hasError,
}: NoSearchResultsProps) => (
  <div
    className={classnames(styles.noResults, {
      [`${styles.noResultsDarkTheme}`]: darkTheme,
    })}
  >
    {hasError ? (
      <>
        <h2
          className={styles.header}
          data-testid="no-search-results-header"
          data-trk="no-search-results"
        >
          Något gick fel
        </h2>
        <p className={styles.text}>Vi upplever tekniska problem just nu.</p>
        <p className={styles.text}>Vänligen försök igen senare.</p>
        {darkTheme ? (
          <ServerErrorRobot
            aria-hidden
            className={styles.robot}
            focusable="false"
          />
        ) : (
          <ServerErrorRobot
            aria-hidden
            className={classnames(styles.robot, styles.robotBackground)}
            focusable="false"
          />
        )}
      </>
    ) : (
      <>
        <h2
          className={styles.header}
          data-testid="no-search-results-header"
          data-trk="no-search-results"
        >
          Din sökning gav inga resultat
        </h2>
        <p className={styles.text}>Kontrollera att alla ord är rättstavade.</p>
        <p className={styles.text}>Försök med andra sökord.</p>
        <p className={styles.text}>Försök med färre sökord.</p>
        {darkTheme ? (
          <NoSearchResultsRobotWhite
            aria-hidden
            className={styles.robot}
            focusable="false"
          />
        ) : (
          <NoSearchResultsRobot
            aria-hidden
            className={styles.robot}
            focusable="false"
          />
        )}
      </>
    )}
  </div>
)

export default NoSearchResults
