import classnames from 'classnames'
import React from 'react'
import { Direction } from 'frontend/enums'
import UpCaret from 'assets/dropdown/up-caret-dark.svg'
import styles from './Caret.module.css'

type CaretProps = {
  animate?: boolean
  direction: Direction
  styling?: string
}

const Caret = ({ animate = false, direction, styling }: CaretProps) => {
  return (
    <UpCaret
      aria-hidden
      className={classnames(styling, {
        [`${styles.animate}`]: animate,
        [`${styles.up}`]: direction === Direction.Up,
        [`${styles.down}`]: direction === Direction.Down,
        [`${styles.right}`]: direction === Direction.Right,
      })}
      focusable="false"
    />
  )
}

export default Caret
