import BrowserCheck from 'components/BrowserCheck/BrowserCheck'
import EnvironmentLabel from 'components/EnvironmentLabel/EnvironmentLabel'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import Footer from 'components/Footer/Footer'
import GoToTop from 'components/GoToTop/GoToTop'
import InfoBanner from 'components/InfoBanner/InfoBanner'
import SiteHeader from 'components/SiteHeader/SiteHeader'
import { CategoryItem } from 'frontend/types'
import React, { useEffect, useState } from 'react'

import styles from './Layout.module.css'
import classnames from 'classnames'

interface Props {
  children: React.ReactNode
  categories: CategoryItem[]
  customBackground?: boolean
}

export const MAIN_CONTENT_ID = 'huvudinnehall'

export default function Layout({
  children,
  categories,
  customBackground,
}: Props) {
  const [renderBrowserCheck, setRenderBrowserCheck] = useState(false)

  useEffect(() => {
    setRenderBrowserCheck(true)
  }, [])

  return (
    <div className={styles.mainBackground}>
      <header>
        <EnvironmentLabel />
        <SiteHeader categoryMenuItems={categories} />
        <InfoBanner />
      </header>
      <main
        id={MAIN_CONTENT_ID}
        className={classnames(styles.main, {
          [`${styles.customBackground}`]: customBackground,
        })}
      >
        <ErrorBoundary>
          <GoToTop />
          {children}
          {renderBrowserCheck && <BrowserCheck />}
        </ErrorBoundary>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}
