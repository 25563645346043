import AlertIcon from 'assets/icons/alerts-information-circle.svg'
import { Announcement } from 'backend/services/v1/edithService.types'
import { getAnnouncement } from 'frontend/lib/Api'
import { logError } from 'frontend/utils/helpers'
import { useStorage } from 'frontend/utils/hooks/useStorage'
import React, { useEffect, useState } from 'react'
import styles from './InfoBanner.module.css'

export const INFO_BANNER_LOCALSTORAGE_KEY = 'info-banner'

const InfoBanner = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [announcement, setAnnouncement] = useState<Announcement | null>(null)
  const { storageValue, isStorageSupported, setStorageValue } = useStorage(
    INFO_BANNER_LOCALSTORAGE_KEY,
  )
  useEffect(() => {
    const fetchData = async () => {
      const response = await getAnnouncement().catch(e => {
        logError(e)
        return null
      })

      setAnnouncement(response)
    }

    fetchData()
  }, [])

  useEffect(() => {
    const hasNewAnnouncement =
      announcement && storageValue !== announcement?.timestamp.toString()

    setIsVisible(Boolean(hasNewAnnouncement))
  }, [storageValue, announcement])

  const handleDismissMessage = () => {
    if (isStorageSupported && announcement) {
      setStorageValue(announcement?.timestamp.toString())
    }
  }
  if (!isVisible || !announcement) return null

  const { message, callToAction } = announcement

  return (
    isVisible && (
      <div className={styles.infoBanner}>
        <div className={styles.infoBannerContent}>
          <div className={styles.infoHeader}>
            <AlertIcon className={styles.icon} />
            <p className={styles.message}>{message}</p>
          </div>
          <div className={styles.infoBannerInteraction}>
            {callToAction?.url && (
              <a
                className={styles.link}
                href={callToAction.url}
                rel="noopener"
                target="_blank"
              >
                {callToAction.urlText}
              </a>
            )}
            <button
              className={styles.closeButton}
              type="button"
              aria-label="Dölj meddelande"
              onClick={handleDismissMessage}
            >
              Dölj meddelande
            </button>
          </div>
        </div>
      </div>
    )
  )
}

export default InfoBanner
