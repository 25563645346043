import classnames from 'classnames'
import LineLoader from 'components/LineLoader/LineLoader'
import NoSearchResults from 'components/NoSearchResults/NoSearchResults'
import { KeyCodes } from 'frontend/enums'
import { SEARCH_TERM_KEY } from 'frontend/lib/constants'
import {
  PreviousSearchTerms,
  QuickSearchCategory,
  QuickSearchProgram,
  QuickSearchSeries,
} from 'frontend/types'
import {
  internalSearchNoResults,
  quickSearchClickOnAutoSuggestProduct,
  quickSearchClickOnPreviousSearchTerm,
} from 'frontend/utils/analytics/dataLayerDispatchers'
import useKeys from 'frontend/utils/hooks/useKeys'
import usePreviousSearches from 'frontend/utils/hooks/usePreviousSearches'
import useStorage from 'frontend/utils/hooks/useStorage'
import { useEffect, useRef, useState } from 'react'
import { QUICK_SEARCH_INPUT_ID } from '../QuickSearchConstants'
import CategoriesResults from './components/CategoriesResults'
import PreviousSearchResults from './components/PreviousSearchResults'
import ProductsResults from './components/ProductsResults'

import ShowResultsButton from './components/ShowResultsButton/ShowResultsButton'
import styles from './QuickSearchResults.module.css'

type QuickSearchResultsProps = {
  isLoading: boolean
  hasError: boolean
  isOpen: boolean
  products: (QuickSearchProgram | QuickSearchSeries)[]
  query: string
  categories: QuickSearchCategory[]
  setDisableSearch: () => void
}

const SEARCH_INPUT_INDEX = -1

const QuickSearchResults = ({
  isLoading,
  hasError,
  isOpen,
  products,
  query,
  categories,
  setDisableSearch,
}: QuickSearchResultsProps) => {
  const { setStorageValue: setSearchTerm } = useStorage(SEARCH_TERM_KEY, true)
  const { previousSearches, addPreviousSearch, removePreviousSearch } =
    usePreviousSearches()

  const handlePreviousSearchRemoval = previousSearchToRemove => {
    document.getElementById(QUICK_SEARCH_INPUT_ID)?.focus()
    removePreviousSearch(previousSearchToRemove)
  }

  const handleOnOptionSelect = (
    record: PreviousSearchTerms | QuickSearchProgram | QuickSearchSeries,
    isPreviousSearchedProduct?: boolean,
  ) => {
    const isPreviousSearchTerm = 'searchTerm' in record

    if (isPreviousSearchTerm) {
      setSearchTerm(record.searchTerm)
      addPreviousSearch(record)
      quickSearchClickOnPreviousSearchTerm(record)
    } else {
      setSearchTerm(record.title)
      addPreviousSearch(record)
      quickSearchClickOnAutoSuggestProduct(
        query,
        record,
        Boolean(isPreviousSearchedProduct),
      )
    }

    setDisableSearch()
  }

  const [focusIndex, setFocusIndex] = useState(SEARCH_INPUT_INDEX)
  const quickSearchContainerRef = useRef<HTMLDivElement>(null)

  const resultAnchors = () =>
    quickSearchContainerRef.current?.querySelectorAll(
      'a',
    ) as NodeListOf<HTMLAnchorElement>

  const searchInput = () => document.getElementById(QUICK_SEARCH_INPUT_ID)

  const incrementIfNotAtBottom = (currentFocusIndex: number) => {
    const incrementedFocusIndex = currentFocusIndex + 1
    const lastResultIndex = resultAnchors().length - 1
    return incrementedFocusIndex < lastResultIndex
      ? incrementedFocusIndex
      : lastResultIndex
  }

  const decrementIfNotAtTop = (currentFocusIndex: number) => {
    const decrementedIndex = currentFocusIndex - 1
    return decrementedIndex > SEARCH_INPUT_INDEX
      ? decrementedIndex
      : SEARCH_INPUT_INDEX
  }

  const addFocusOnNodeIfApplicable = () => {
    const resultsExist = resultAnchors().length > 0
    if (!resultsExist || !isOpen) return

    const target =
      focusIndex === SEARCH_INPUT_INDEX
        ? searchInput()
        : resultAnchors()[focusIndex]

    target?.focus()
  }

  useEffect(addFocusOnNodeIfApplicable, [focusIndex])

  useEffect(() => {
    setFocusIndex(SEARCH_INPUT_INDEX)
  }, [query])

  const onUpArrowPress = () => {
    setFocusIndex(decrementIfNotAtTop)
  }
  const onDownArrowPress = () => {
    setFocusIndex(incrementIfNotAtBottom)
  }
  useKeys(isOpen, {
    [KeyCodes.UP_ARROW_KEY_CODE]: onUpArrowPress,
    [KeyCodes.DOWN_ARROW_KEY_CODE]: onDownArrowPress,
  })

  const noResults =
    query.length > 0 && products.length === 0 && categories.length === 0

  const isSearch = query.length > 0

  useEffect(() => {
    if (noResults && !isLoading) internalSearchNoResults(query)
  }, [noResults, query, isLoading])

  if (query.length === 0 && previousSearches.length === 0)
    return <div ref={quickSearchContainerRef} />

  return (
    <div
      className={classnames(styles.suggestContainer, {
        [`${styles.hide}`]: !isOpen,
      })}
      data-testid="quick-search-results"
      ref={quickSearchContainerRef}
    >
      <LineLoader showLoadingAnimation={isLoading} />
      <div className={styles.suggestions}>
        {noResults && !isLoading && isOpen && (
          <NoSearchResults hasError={hasError} />
        )}

        {!isSearch && (
          <>
            <h2 className={styles.header}>Tidigare sökningar</h2>
            <ul data-trk-internal-search-element="Historik">
              <PreviousSearchResults
                previousSearches={previousSearches}
                query={query}
                onClick={handleOnOptionSelect}
                removePreviousSearch={handlePreviousSearchRemoval}
              />
            </ul>
          </>
        )}

        {isSearch && !noResults && (
          <ul data-testid="quick-search-result">
            {categories.length > 0 && (
              <CategoriesResults categories={categories} query={query} />
            )}
            {products.length > 0 && (
              <ProductsResults
                products={products}
                query={query}
                onClick={handleOnOptionSelect}
              />
            )}
          </ul>
        )}
      </div>

      {isSearch && !noResults && (
        <ShowResultsButton query={query} showLoadingAnimation={isLoading} />
      )}
    </div>
  )
}

export default QuickSearchResults
