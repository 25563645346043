import React from 'react'
import { handleDuration } from 'frontend/utils/helpers'
import { MediaFormat } from 'frontend/enums'
import classnames from 'classnames'
import { QuickSearchProgram, QuickSearchSeries } from 'frontend/types'
import Badges from 'components/Badges/Badges'
import { Dot } from 'components/Card/CardMetaData'
import HighlightText from './HighlightText'
import styles from '../QuickSearchResults.module.css'

type QuickSearchProduct = QuickSearchProgram | QuickSearchSeries

type ProductsResultProps = {
  product: QuickSearchProduct
  query: string
  onClick: (product: QuickSearchProduct, previousSearch?: boolean) => void
  previousSearch?: boolean
}

type ProductsResultsProps = {
  products: QuickSearchProduct[]
  query: string
  onClick: (program: QuickSearchProduct, previousSearch?: boolean) => void
}

export const ProductResult = ({
  product,
  query,
  onClick,
  previousSearch,
}: ProductsResultProps) => {
  const isProgram = 'mainTitle' in product

  const { isAudioDescribed, isSignLanguageInterpreted, format } =
    product as QuickSearchProgram
  const isRadioProduct = format === MediaFormat.AUDIO

  const hasBadge =
    isAudioDescribed || isRadioProduct || isSignLanguageInterpreted

  return (
    <a
      className={classnames(styles.suggestion, {
        [`${styles.previousSearchItem}`]: previousSearch,
      })}
      href={product.link}
      onClick={() => onClick(product, previousSearch)}
    >
      <figure className={styles.productImageFigure}>
        <img
          aria-hidden="true"
          alt={`Sökresultat miniatyr - ${product.title}`}
          className={styles.productImage}
          src={product.image['128x72']}
        />
      </figure>
      <div className={styles.metadataWrap}>
        <span className={styles.title} data-testid="quick-search-result-title">
          <HighlightText text={product.title} highlight={query} />
        </span>

        <div className={styles.metadataText}>
          {hasBadge && (
            <>
              <Badges
                isAudioDescribed={isAudioDescribed}
                isRadioProduct={isRadioProduct}
                isSignLanguageInterpreted={isSignLanguageInterpreted}
                isQuickSearchBadge
              />
              <Dot />
            </>
          )}
          {isProgram ? (
            <>
              <span>{handleDuration(product.duration)}</span>
              {(product.mainTitle || product.mainGenre) && <Dot />}
              <HighlightText
                text={product.mainTitle || product.mainGenre}
                highlight={query}
              />
            </>
          ) : (
            <span className={styles.seriesLabel}>Serie</span>
          )}
        </div>
      </div>
    </a>
  )
}

const ProductsResults = ({
  products,
  query,
  onClick,
}: ProductsResultsProps) => (
  <>
    {products.map(product => {
      const { productType, id } = product
      return (
        <li key={`${productType}-${id}`}>
          <ProductResult
            product={product}
            query={query}
            onClick={onClick}
            previousSearch={false}
          />
        </li>
      )
    })}
  </>
)

export default ProductsResults
