import LoadingBubbles from 'components/Animations/LoadingBubbles/LoadingBubbles'
import { urlEncodeParams } from 'frontend/utils/urlHelpers'
import styles from './ShowResultsButton.module.css'

interface Props {
  showLoadingAnimation: boolean
  query: string
}

const ShowResultsButton = ({ showLoadingAnimation, query }: Props) => {
  return (
    <span className={styles.button}>
      {showLoadingAnimation ? (
        <LoadingBubbles />
      ) : (
        <a
          data-testid="quick-search-show-all-button"
          className={styles.link}
          href={`/sok?${urlEncodeParams({ query })}`}
          data-trk-internal-search-element="Visa alla"
        >
          <span>Visa alla resultat</span>
        </a>
      )}
    </span>
  )
}

export default ShowResultsButton
