import React, { useEffect, useState } from 'react'
import Bowser from 'bowser'
import { checkStorageSupport } from 'frontend/utils/helpers'
import styles from './BrowserCheck.module.css'

export const BROWSER_SUPPORT_CHECK_KEY = 'browser_support_check'

const INVALID_BROWSERS = {
  'Internet Explorer': '<12',
  'Microsoft Edge': '<79',
  'Samsung Internet for Android': '<6',
  Android: '<4.4.2',
  chrome: '<78',
  chromium: '<78',
  firefox: '<71',
  Googlebot: '<2.1',
  iOS: '<12',
  safari: '<12',
}

const BrowserCheck = () => {
  // initial state is true to avoid flickering
  const [browserIsSupported, setBrowserIsSupported] = useState<boolean>(true)
  const [hasDismissed, setHasDismissed] = useState<boolean>(false)

  const browser = Bowser.getParser(window.navigator.userAgent)

  const browserSupportsLocalStorage = checkStorageSupport('localStorage')

  const isValidBrowser = !browser.satisfies(INVALID_BROWSERS)

  useEffect(() => {
    let hasDismissedBefore: boolean = false

    if (!isValidBrowser) {
      setBrowserIsSupported(false)
    } else {
      setBrowserIsSupported(true)
    }

    if (browserSupportsLocalStorage) {
      hasDismissedBefore =
        Boolean(localStorage.getItem(BROWSER_SUPPORT_CHECK_KEY)) || false
    }

    if (hasDismissedBefore) {
      setHasDismissed(true)
    }
  }, [])

  const dismissWarning = () => {
    if (browserSupportsLocalStorage) {
      localStorage.setItem(BROWSER_SUPPORT_CHECK_KEY, new Date().toString())
    }

    setHasDismissed(true)
  }

  if (browserIsSupported || hasDismissed) return null

  return (
    <div className={styles.wrapper} data-testid="browser-check-warning">
      <p className={styles.paragraph}>
        UR Play
        <a
          className={styles.link}
          href="https://kontakt.ur.se/guide/systemkrav-for-ur-play?category=tekniska-fragor"
          target="_blank"
          rel="noopener"
        >
          stödjer tyvärr inte din webbläsare.
        </a>
        Det kan påverka din upplevelse.
      </p>
      <button className={styles.button} onClick={dismissWarning} type="button">
        Okej
      </button>
    </div>
  )
}

export default BrowserCheck
