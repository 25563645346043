import React from 'react'
import ServerErrorRobot from 'assets/robots/robot-server-error.svg'
import styles from './ErrorWindow.module.css'

type ErrorWindowProps = {
  children: React.ReactNode
  title?: string
}

const ErrorWindow = ({
  children,
  title = 'Vi har tekniska problem!',
}: ErrorWindowProps) => {
  return (
    <div className={styles.sectionHeader}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{children}</p>
      <div className={styles.icon}>
        <ServerErrorRobot aria-hidden focusable="false" />
      </div>
    </div>
  )
}

export default ErrorWindow
